<template>

      <v-app class>
        <div>
        <v-alert
      v-model="alert"
      border="left"
      dark
      dismissible
      class="alert"
      :class="{'alert-error':alertType=='error', 'alert-success':alertType=='success'}"
    >
    {{alertMessage}}
        </v-alert>
        </div>
        <div class="row">
          <div class="col-md-10 ml-auto mr-auto">
            <v-container>
              <div class="mb-8 mt-8 text-center">
                <h1>Job Titles</h1>
              </div>
              <!-- table  -->
            <v-data-table loading-text="Loading job-title... Please wait"
              :loading="!jobTitles.length"
              :headers= 'headers'
              :items="jobTitles"
             >
                <template v-slot:item.statusDate="{ item }" >
                  {{item.statusDate | formatDate}}
              </template>
                <template v-slot:item.salaryAmount="{ item }" >
                  {{ formatMoney (item.salaryAmount)}}
              </template>
                <template v-slot:item.actions="{ item }" >
                    <div class="d-flex">
                      <Button :btnType="'Submit'" :label="'Approve'" @onClick="openConfiModal(item)" class="mr-2"/>
                    <Button :btnType="'Cancel'" :label="'Reject'" @onClick="openCancelModal(item)" />
                    </div>
              </template>
          </v-data-table>

    <v-dialog v-model="dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline text-center"><h3>Reason for rejecting</h3></v-card-title>
        <v-divider class="mx-4 mb-5"></v-divider>
        <v-card-text class="mt-5">
          <v-form ref="commentForm" v-model="valid">
            <TextField  v-model="formData.comments" :label="'Comment'"/>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <Button
            :label="'Reject'"
            :btnType="'Submit'"
            @onClick="reject"
            :disabled="!valid"
            class="mr-4"
          />
          <v-btn color="green darken-1" text @click="dialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
     <ConfirmationDialog :title="'Are you sure you want to submit?'" :isLoading="isLoading" :dialog="confDialog" :btnTitle="'Ok'" @close="confDialog = false" @btnAction="approve"/>
            </v-container>
          </div>
        </div>
      </v-app>

</template>
<script>

import TextField from '@/components/ui/form/TextField.vue'
import Button from '@/components/ui/form/Button.vue'
import ConfirmationDialog from '@/components/ui/confirmationDialog.vue'
import { FETCH_PENDING_JOBTITLE, APPROVE_JOBTITLE, REJECT_JOBTITLE } from '@/store/action-type'

export default {
  components: {
    Button,
    TextField,
    ConfirmationDialog
  },
  data () {
    return {
      formData: {
        comments: ''
      },
      search: '',
      alertMessage: '',
      alert: false,
      alertType: '',
      confDialog: false,
      dialog: false,
      valid: false,
      data: null,
      item: null,
      headers: [
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'salary Amount',
          value: 'salaryAmount'
        },
        {
          text: 'Date',
          value: 'statusDate'
        },
        {
          text: 'Action',
          value: 'action'
        },
        {
          text: 'Actions',
          value: 'actions'
        }
      ]
    }
  },
  computed: {
    jobTitles () {
      return this.$store.getters.pendingJobTitles
    }
  },
  methods: {
    showAlertMessage (message, type) {
      this.alertMessage = message
      this.alert = true
      this.alertType = type
    },
    approve () {
      this.isLoading = true
      this.$store
        .dispatch(APPROVE_JOBTITLE, this.item)
        .then((result) => {
          this.showAlertMessage('Operation successful', 'success')
          this.isLoading = false
          this.confDialog = false
        })
        .catch((error) => {
          console.log(error)
          this.showAlertMessage('Operation failed', 'error')
        }).finally(() => {
          this.isLoading = false
          this.confDialog = false
        })
    },
    openConfiModal (item) {
      this.item = item
      this.confDialog = true
    },
    openCancelModal (item) {
      this.data = item
      this.dialog = true
    },
    reject () {
      this.$store
        .dispatch(REJECT_JOBTITLE, this.data)
        .then((result) => {
          this.showAlertMessage('Job Title Rejected Successfully', 'success')
        })
        .catch((error) => {
          console.log(error)
          this.showAlertMessage('Job Title Reject failed', 'error')
        }).finally(() => {
          this.dialog = false
        })
    },
    formatMoney (amount, decimalCount = 2, decimal = '.', thousands = ',') {
      try {
        decimalCount = Math.abs(decimalCount)
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount

        const negativeSign = amount < 0 ? '-' : ''

        let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString()
        let j = (i.length > 3) ? i.length % 3 : 0

        return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : '')
      } catch (e) {
        console.log(e)
      }
    },
    deleteItem (item) {
      console.log(item)
    }
  },
  mounted () {
    this.$store
      .dispatch(FETCH_PENDING_JOBTITLE)
      .then(() => {})
      .catch((error) => {
        console.log(error)
        this.showAlertMessage('There are no items pending your approval', 'warning')
      })
  }
}
</script>
<style scoped>
.search-btn {
  background-color: #e6e3e3;
  height: 40px;
  border-radius: 3px !important;
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
.alert {
  position: fixed;
  z-index: 9999;
  top: 5px;
  min-width: 50%;
}
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
</style>
